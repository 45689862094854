export const AppRootElement = document.getElementById('root') as HTMLElement;

let apiKey = AppRootElement.dataset.key;
let baseUrl = AppRootElement.dataset.api;
if (apiKey === 'API_KEY') {
    apiKey = process.env.REACT_APP_API_KEY;
}
if (baseUrl === 'API_TARGET_SERVER') {
    baseUrl = process.env.REACT_APP_API_ENDPOINT_V1;
}

export const appConfig = {
    apiKey: apiKey ?? 'API_KEY',
    apiV1Endpoint: baseUrl ?? 'API_TARGET_SERVER',
};
