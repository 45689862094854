import * as React from 'react';
import { Button, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExtendedTypography } from '../theme';
import { useNavigate } from 'react-router';

const Error: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Container className={'page'}>
            <ExtendedTypography variant="h2">
                {t('error.headline')}
            </ExtendedTypography>
            <ExtendedTypography variant="highlight">
                Bitte versuchen Sie es später noch einmal.
            </ExtendedTypography>
            <Grid
                container justifyContent="center"
            >
                <Button variant={'contained'} onClick={() => {
                    navigate('/');
                }}>
                    Verstanden
                </Button>
            </Grid>
        </Container>
    );
};

export default Error;
