import useConfig from './hooks/useConfig';
import React from 'react';
import { createTheme, ThemeOptions, Typography, TypographyProps } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        withSpace: true;
        highlight: true;
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    withSpace: React.CSSProperties;
    highlight: React.CSSProperties;
}


const useTheme = () => {
    const { config } = useConfig();

    const colors = {
        primary: config.branding.colors.primary,
        textColor: config.branding.colors.textColor,
        linkColor: config.branding.colors.linkColor,
        selectBackground: config.branding.colors.selectBackground,
    };

    const fontFamily = config.branding.fontFamily;

    const customTheme = createTheme({
        palette: {
            primary: {
                main: colors.primary,
            },
            text: {
                main: colors.textColor,
            },
            action: {
                main: colors.linkColor,
            },
        },
        typography: {
            fontFamily: fontFamily.join(','),
            fontSize: 14,
            h1: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 60,
                lineHeight: '1em',
                display: 'block',
                textAlign: 'center',
            },
            h2: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 45,
                color: colors.primary,
                lineHeight: '1em',
                display: 'block',
                textAlign: 'center',
            },
            h3: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 26,
                color: colors.textColor,
                lineHeight: '1.35em',
                display: 'block',
                textAlign: 'center',
            },
            h4: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 27,
                color: colors.textColor,
                lineHeight: '1em',
                display: 'block',
                textAlign: 'center',
            },
            h5: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 14,
                color: colors.textColor,
                lineHeight: '1em',
                display: 'block',
                textAlign: 'center',
            },
            h6: {
                fontFamily: '',// use font family for h1-h6 from parent page
                fontSize: 14,
                color: colors.textColor,
                lineHeight: '1.45em',
                display: 'block',
            },
            withSpace: {
                fontSize: 20,
                color: colors.textColor,
                marginTop: '1em',
                marginBottom: '1em',
                display: 'block',
                textAlign: 'center',
            },
            highlight: {
                fontSize: 26,
                color: colors.textColor,
                marginTop: '1rem',
                marginBottom: '1rem',
                display: 'block',
                textAlign: 'center',
            },
        } as ExtendedTypographyOptions,
        components: {
            MuiInput: {
                styleOverrides: {
                    input: {
                        color: colors.textColor,
                    },
                    underline: {
                        '&:after': {
                            borderBottomColor: colors.primary,
                        },
                        '&:before': {
                            borderBottomColor: colors.textColor,
                        },
                    },
                    root: {
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: colors.primary,
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': {
                            color: colors.primary,
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: colors.textColor,
                    },
                    asterisk: {
                        color: colors.primary,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        color: colors.textColor,
                    },
                    icon: {
                        color: colors.textColor,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: colors.textColor,
                        backgroundColor: colors.selectBackground,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: colors.textColor,
                        '&.Mui-checked': {
                            color: colors.primary,
                        },
                    },
                },
            },
        },
    } as ThemeOptions);

    return { colors: colors, customTheme: customTheme };
};

export const ExtendedTypography = (props: TypographyProps) => {


    if (['withSpace', 'highlight'].includes(props.variant ?? '')) {
        props = {
            ...props,
            // their type mentions to use component, but does not list it
            // @ts-ignore
            component: 'p',
        };
    }

    return <Typography {...props} />;
};

export default useTheme;

