import type { FC } from 'react';
import {CircularProgress, Stack} from '@mui/material';

const LoadingSpinner: FC = () => {

    return (
     <Stack alignItems="center" height={200} justifyContent="center">
         <CircularProgress size="100px"></CircularProgress>
     </Stack>
    );
};

export default LoadingSpinner;
