import type { FC } from 'react';
import { useContext, useState } from 'react';
import './styles/App.scss';
import { ThemeProvider } from '@mui/material/styles';
import useTheme from './theme';
import { RouterProvider } from 'react-router';
import routes from './routes';
import { backendApi } from './api/backendApi';
import ConfigContext, { OptionalConfig } from './contexts/ConfigContext';
import LoadingSpinner from './components/LoadingSpinner';

const App: FC = () => {
    const [isConfigDataLoaded, setIsConfigDataLoaded] = useState(false);
    const theme = useTheme();
    const { saveConfig } = useContext(ConfigContext);

    const getConfig = async () => {
        const data: OptionalConfig = await backendApi.getConfig();

        if (data) {
            saveConfig(data);
            setIsConfigDataLoaded(true);
        }
    };

    if (!isConfigDataLoaded) {
        getConfig();
    }

    return (
        <div className="App">
            <ThemeProvider theme={theme.customTheme}>
                {
                    isConfigDataLoaded
                        ? <RouterProvider router={routes} />
                        : <LoadingSpinner />
                }
            </ThemeProvider>
        </div>
    );
};

export default App;
