import { appConfig } from '../config';
import { RequestBody } from '../types/requestBody';

class BackendApi {
    async getConfig() {
        return fetch(`${appConfig.apiV1Endpoint}/v1/config?apiKey=${appConfig.apiKey}`, {
            method: 'GET',
        }).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        });
    }

    async postFormData(formData: RequestBody, idempotencyKey: string) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Idempotency-Key': idempotencyKey,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        };

        return fetch(`${appConfig.apiV1Endpoint}/v1/voucher?apiKey=${appConfig.apiKey}`, requestOptions)
            .then((response) => response.json());
    }

    async postPayPalOrder(formId: number, data: object) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                formId: formId,
                data: data,
            }),
        };

        return fetch(`${appConfig.apiV1Endpoint}/v1/payment?apiKey=${appConfig.apiKey}`, requestOptions)
            .then((response) => response.json());
    }

}

export const backendApi = new BackendApi();
