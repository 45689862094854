import { createContext, FC, ReactNode, useState } from 'react';

export type WorkflowContextValue = {
    config: WorkflowContextConfig,
    setWorkflowValues: (formId: number, values: number) => void,
    resetWorkflowValues: () => void,
}

export type WorkflowContextConfig = {
    formId: number | null;
    amount: number;
}

const initialConfig: WorkflowContextConfig = {
    formId: null,
    amount: 0,
};

export type ConfigProviderProps = {
    children: ReactNode | null;
}

const WorkflowContext = createContext<WorkflowContextValue>({
    config: initialConfig,
    setWorkflowValues: (formId: number, values: number) => {
        console.log('setWorkflowValues:empty');
    },
    resetWorkflowValues: () => {
        console.log('resetWorkflowValues:empty');
    },
});


export const WorkflowContextProvider: FC<ConfigProviderProps> = (props) => {
    const { children } = props;
    const [config, setConfig] = useState<WorkflowContextConfig>(initialConfig);

    let value: WorkflowContextValue = {
        config,
        setWorkflowValues: (formId: number, amount: number): void => {
            console.log('setFormId');
            config.formId = formId;
            config.amount = amount;
            setConfig(config);
        },
        resetWorkflowValues: (): void => {
            setConfig(initialConfig);
        },
    };
    return (
        <WorkflowContext.Provider value={value}>
            {children}
        </WorkflowContext.Provider>
    );
};

export default WorkflowContext;
