import FormComponent from './pages/FormComponent';
import Payment from './pages/Payment';
import Done from './pages/Done';
import Error from './pages/Error';
import { createHashRouter } from 'react-router-dom';
import { WorkflowContextProvider } from './contexts/WorkflowContext';

const routes = createHashRouter([
    {
        path: '/',
        element: (
            <WorkflowContextProvider>
                <FormComponent />
            </WorkflowContextProvider>
        ),
    },
    {
        path: '/payment',
        element: (
            <WorkflowContextProvider>
                <Payment />
            </WorkflowContextProvider>
        ),
    },
    {
        path: '/done',
        element: (
            <WorkflowContextProvider>
                <Done />
            </WorkflowContextProvider>
        ),
    },
    {
        path: '/error',
        element: (
            <WorkflowContextProvider>
                <Error />
            </WorkflowContextProvider>
        ),
    },
]);

export default routes;
