import { Input, Slider } from '@mui/material';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import useConfig from '../hooks/useConfig';
import useTheme from '../theme';

const InputSlider: React.FC<{ value: number, setValue: (number: number) => void, min: number, max: number }> = (props) => {
    const setValue = props.setValue;
    const value = props.value;
    const min = props.min;
    const max = props.max;
    const { config } = useConfig();
    const customTheme = useTheme();

    const spacing = config.branding.spacing;

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue as number);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value === '' ? 0 : Number(event.target.value);
        setValue(newValue);
    };

    const handleBlur = () => {
        if (value < min) {
            setValue(min);
        } else if (value > max) {
            setValue(max);
        }
    };

    const marks = [];

    for (let step = min; step <= max;) {
        const value = max / 100 * step;
        marks.push(
            {
                value: value,
                label: value,
            },
        );

        step += 10;
    }

    return (
        <Grid container spacing={spacing}>
            <Grid item xs={0} sm={10} display={{ xs: 'none', sm: 'block' }}>
                <Slider
                    value={value}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    marks={marks}
                    min={min}
                    max={max}
                    sx={{
                        '.MuiSlider-markLabel': {
                            color: customTheme.colors.textColor,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Input
                    value={value}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: 1,
                        min: min,
                        max: max,
                        type: 'number',
                    }}
                    sx={{
                        width: '100%',
                        input: {
                            textAlign: 'center',
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default InputSlider;
