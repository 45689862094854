import type { FC } from 'react';
import * as React from 'react';
import type { TextFieldProps } from 'formik-mui';
import InputSlider from './InputSlider';
import { FormLabel } from '@mui/material';

type FormikInputSliderProps = TextFieldProps & {
    min: number,
    max: number
};
const FormikInputSlider: FC<FormikInputSliderProps> = (props: FormikInputSliderProps) => {
    return (
        <>
            <FormLabel>
                {props.label}
            </FormLabel>
            <InputSlider
                value={props.field.value}
                setValue={number => props.form.setFieldValue(props.field.name, number)}
                min={props.min}
                max={props.max}
            />
        </>
    );
};

export default FormikInputSlider;
