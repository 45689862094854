export interface RequestBody {
    amount: number | undefined,
    title: string,
    firstName: string,
    lastName: string,
    street: string,
    postalCode: string,
    city: string,
    phone: string,
    email: string,
    comment: string
}

export enum CreateVoucherResponseEnum {
    SAVED = 'saved',
    DONE = 'done',
    ERROR = 'error',
}

export type CreateVoucherResponse = {
    readonly status: CreateVoucherResponseEnum
    readonly formId: number
}
