import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Collapse, Container, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import useMounted from '../hooks/useMounted';
import { number, object, string } from 'yup';
import useTheme from '../theme';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import useConfig from '../hooks/useConfig';
import uuid from 'react-uuid';
import WorkflowContext from '../contexts/WorkflowContext';
import FormikInputSlider from '../components/FormikInputSlider';
import SalutationSelect from '../components/SalutationSelect';
import { backendApi } from '../api/backendApi';
import { CreateVoucherResponseEnum } from '../types/requestBody';

const FormComponent: React.FC = () => {
    const { setWorkflowValues } = useContext(WorkflowContext);
    const idempotencyKey = uuid();
    const { t } = useTranslation();
    const mounted = useMounted();
    const navigate = useNavigate();
    const { config } = useConfig();
    const customTheme = useTheme();
    const [isDataPolicyAccepted, setIsDataPolicyAccepted] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const spacing = config.branding.spacing;
    const minimumAmount = config.minimumAmount;
    const maximumAmount = config.maximumAmount;
    const privacyPolicyUrl = config.privacyPolicyLink;

    const lockSchema = object({
        amount: number()
            .positive()
            .min(minimumAmount, t('errors.minimum', { value: minimumAmount }))
            .max(maximumAmount, t('errors.maximum', { value: maximumAmount }))
            .required(t('formFields.amount') + ' ' + t('errors.required')),
        title: string()
            .notRequired(),
        firstName: string()
            .required(t('formFields.firstName') + ' ' + t('errors.required')),
        lastName: string()
            .required(t('formFields.lastName') + ' ' + t('errors.required')),
        street: string()
            .required(t('formFields.street') + ' ' + t('errors.required')),
        postalCode: string()
            .required(t('formFields.postalCode') + ' ' + t('errors.required')),
        city: string()
            .required(t('formFields.city') + ' ' + t('errors.required')),
        phone: string()
            .required(t('formFields.phone') + ' ' + t('errors.required')),
        email: string()
            .required(t('formFields.email') + ' ' + t('errors.required')),
        comment: string()
            .trim()
            .nullable()
    });

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDataPolicyAccepted((event.target.checked));
    };

    return (
        <Container className={'page'}>
            <Formik
                initialValues={{
                    amount: minimumAmount,
                    title: 'U',
                    firstName: '',
                    lastName: '',
                    street: '',
                    postalCode: '',
                    city: '',
                    phone: '',
                    email: '',
                    comment: '',
                }}
                validationSchema={lockSchema}
                onSubmit={async (values, {
                    // setErrors,
                    setStatus,
                    setSubmitting,
                }): Promise<void> => {
                    if (!isDataPolicyAccepted) {
                        setIsAlertOpen(true);
                    } else {
                        setIsAlertOpen(false);
                        try {
                            // @ts-ignore
                            await backendApi.postFormData(values, idempotencyKey).then((response: CreateVoucherResponse) => {
                                if (response.status === CreateVoucherResponseEnum.SAVED || response.status === CreateVoucherResponseEnum.DONE) {
                                    setWorkflowValues(response.formId, values.amount);
                                    navigate('/payment');
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                                if (response.status === CreateVoucherResponseEnum.ERROR) {
                                    //TODO: handle
                                }
                                // @ts-ignore
                            }).catch((err) => {
                                console.error('err: ', err);
                            });

                            // use mounted?
                            if (mounted.current) {
                                navigate('/payment');
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (error) {
                            console.error(error);
                            if (mounted.current) {
                                setStatus({ success: false });
                                // setErrors({ submit: error.message });
                                setSubmitting(false);
                            }
                        }
                    }
                }}
            >
                {({
                      values,
                  }): JSX.Element => (

                    <Form noValidate>
                        <Grid container spacing={spacing}>
                            <Grid item xs={12}>
                                <Field
                                    component={FormikInputSlider}
                                    fullWidth
                                    type="number"
                                    label={t('formFields.amount')}
                                    name="amount"
                                    variant="standard"
                                    required
                                    // min and max used by FormikInputSlider
                                    min={minimumAmount}
                                    max={maximumAmount}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={SalutationSelect}
                                    fullWidth
                                    label={t('formFields.pleaseChoose')}
                                    name="title"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.firstName')}
                                    name="firstName"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.lastName')}
                                    name="lastName"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.street')}
                                    name="street"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.postalCode')}
                                    name="postalCode"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.city')}
                                    name="city"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.phone')}
                                    name="phone"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.email')}
                                    name="email"
                                    variant="standard"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    fullWidth
                                    label={t('formFields.comment')}
                                    name="comment"
                                    variant="standard"
                                    multiline
                                    rows={5}
                                />
                            </Grid>
                        </Grid>
                        <Collapse in={isAlertOpen}>
                            <Alert
                                variant="filled"
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setIsAlertOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mt: spacing }}
                            >
                                {t('privacy.error')}
                            </Alert>
                        </Collapse>
                        <Stack direction="row" sx={{ mt: spacing }}>
                            <Field
                                component={Checkbox}
                                type="checkbox"
                                checked={isDataPolicyAccepted}
                                onChange={handleCheckbox}
                            />
                            <Typography sx={{ color: customTheme.colors.textColor }}>
                                {t('privacy.policyText1')}
                                <Link
                                    color={customTheme.colors.linkColor}
                                    underline="hover"
                                    target={'_blank'}
                                    href={privacyPolicyUrl}>{t('privacy.policyLink')}
                                </Link>
                                {t('privacy.policyText2')}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="start">
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ mt: spacing }}
                            >
                                {t('formFields.submit')}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default FormComponent;
