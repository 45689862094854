import * as React from 'react';
import { destroySDKScript, getScriptID, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import useTheme from '../../theme';
import { CreateOrderRequestBody, PurchaseUnit } from '@paypal/paypal-js/types/apis/orders';
import { PayPalScriptOptions } from '@paypal/paypal-js';
import { Button, Grid } from '@mui/material';

type PayPalButtonWrapperProps = { options: PayPalScriptOptions, onSuccess: (details: object) => Promise<void>, amount: number, currency: string, showSpinner: boolean };
const PayPalButtonWrapper: React.FC<PayPalButtonWrapperProps> = (props) => {
        const [{ isPending }, dispatch] = usePayPalScriptReducer();
        const customTheme = useTheme();

        let loader = null;
        if (props.showSpinner && isPending) {
            loader = <div className={'loader-wrapper'}>
                <div className="loader" style={{ borderTopColor: customTheme.colors.primary }} />
            </div>;
        }

        let devButton = null;
        if (process.env.REACT_APP_API_ENVIRONMENT === 'development') {
            devButton =
                <Grid
                    container justifyContent="center"
                >
                    <Button
                        sx={{
                            marginBottom: '2rem',
                        }}
                        type="button"
                        variant={'contained'}
                        onClick={() => {
                            destroySDKScript(getScriptID(props.options));
                            dispatch({
                                type: 'setLoadingStatus',
                                // @ts-ignore
                                value: 'initial',
                            });
                        }}
                    >
                        Dev: Reload Paypal
                    </Button>
                </Grid>;
        }

        return (<>
                {loader}
                {devButton}
                <PayPalButtons
                    createOrder={(data, actions) => {
                        console.log('createOrder');
                        let purchaseUnit: PurchaseUnit = {
                            amount: {
                                value: props.amount.toString(),
                                currency_code: props.currency,
                            },
                        };
                        let options: CreateOrderRequestBody = {
                            purchase_units: [purchaseUnit],
                        };
                        return actions.order.create(options);
                    }}
                    onApprove={(data, actions) => {
                        console.log('onApprove');
                        //TODO: no ignore
                        // @ts-ignore
                        return actions.order.capture().then((details: object) => {
                            console.log('onSuccess');
                            props.onSuccess(details);
                        });
                    }}
                    onCancel={(data, actions) => {
                        console.log('onCancel');
                        //TODO: Show a cancel page, or return to cart
                    }}
                    onError={(err) => {
                        console.log('onError');
                        //TODO: For example, redirect to a specific error page
                    }}
                />
            </>
        );
    }
;

export default PayPalButtonWrapper;
;
