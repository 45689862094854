import * as React from 'react';
import { Button, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExtendedTypography } from '../theme';
import { useNavigate } from 'react-router';

const Done: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Container className={'page'}>
            <ExtendedTypography variant="h2">
                {t('done.created')}
            </ExtendedTypography>
            <ExtendedTypography variant="highlight">
                Vielen Dank für Ihre Bestellung!
            </ExtendedTypography>
            <ExtendedTypography variant="withSpace">
                Sie erhalten Ihren Gutschein in Kürze als PDF per Mail an die von Ihnen
                angegebene E-Mail-Adresse.
            </ExtendedTypography>
            <ExtendedTypography variant="withSpace">
                Sollten Sie den Gutschein nicht innerhalb der nächsten 10 Minuten erhalten, so prüfen Sie bitte auch
                Ihren Spam-Ordner.
            </ExtendedTypography>
            <ExtendedTypography variant="withSpace">
                Viel Freude beim Verschenken!
            </ExtendedTypography>
            <Grid
                container justifyContent="center"
            >
                <Button variant={'contained'} onClick={() => {
                    navigate('/');
                }}>
                    Neuer Gutschein
                </Button>
            </Grid>
        </Container>
    );
};

export default Done;
