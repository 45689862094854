import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de  from "./i18n/de.json"

const resources = {
    ...de,
 };

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
