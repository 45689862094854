import type { FC, ReactNode } from 'react';
import { createContext, useState } from 'react';

export interface Config {
    currency: string,
    clientId: string,
    privacyPolicyLink: string,
    versandkosten: number,
    minimumAmount: number,
    maximumAmount: number,
    branding: {
        fontFamily: string[],
        colors: {
            primary: string,
            textColor: string,
            linkColor: string,
            selectBackground: string
        },
        spacing: number
    }
    translations: {}
}

export interface OptionalConfig {
    clientId?: string,
    privacyPolicyLink?: string,
    minimumAmount?: number,
    maximumAmount?: number,
    branding?: {
        fontFamily?: string[],
        colors?: {
            primary?: string,
            textColor?: string,
            linkColor?: string,
            selectBackground?: string
        },
        spacing?: number
    }
    translations?: {}
}

export interface ConfigContextValue {
    config: Config;
    saveConfig: (update: OptionalConfig) => void;
}

interface ConfigProviderProps {
    children?: ReactNode;
}

const initialConfig: Config = {
    currency: 'EUR',
    clientId: '',
    privacyPolicyLink: 'https://www.glutundwasser.de/_service/datenschutz/',
    versandkosten: 0,
    minimumAmount: 5,
    maximumAmount: 500,
    branding: {
        fontFamily: [
            'Segoe UI',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif',
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ],
        colors: {
            primary: '#EA5045',
            textColor: '#FFFFFF',
            linkColor: '#EA5045',
            selectBackground: '#353535',
        },
        spacing: 4,
    },
    translations: {},
};


const ConfigContext = createContext<ConfigContextValue>({
    config: initialConfig,
    saveConfig: () => {
    },
});

export const ConfigProvider: FC<ConfigProviderProps> = (props) => {
    const { children } = props;
    const [config, setConfig] = useState<Config>(initialConfig);

    const saveConfig = (updatedConfig: OptionalConfig): void => {
        const completedConfigData = {
            currency: initialConfig.currency,
            clientId: updatedConfig.clientId ?? '',
            privacyPolicyLink: updatedConfig.privacyPolicyLink ?? initialConfig.privacyPolicyLink,
            versandkosten: initialConfig.versandkosten,
            minimumAmount: updatedConfig.minimumAmount ?? initialConfig.minimumAmount,
            maximumAmount: updatedConfig.maximumAmount ?? initialConfig.maximumAmount,
            branding: {
                fontFamily: updatedConfig.branding?.fontFamily ?? initialConfig.branding.fontFamily,
                colors: {
                    primary: updatedConfig.branding?.colors?.primary ?? initialConfig.branding.colors.primary,
                    textColor: updatedConfig.branding?.colors?.textColor ?? initialConfig.branding.colors.textColor,
                    linkColor: updatedConfig.branding?.colors?.linkColor ?? initialConfig.branding.colors.linkColor,
                    selectBackground: updatedConfig.branding?.colors?.selectBackground ?? initialConfig.branding.colors.selectBackground,
                },
                spacing: updatedConfig.branding?.spacing ?? initialConfig.branding.spacing,
            },
            translations: updatedConfig.translations ?? initialConfig.translations,
        };

        setConfig(completedConfigData);
    };

    return (
        <ConfigContext.Provider
            value={{
                config,
                saveConfig,
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigContext;
