import type { FC } from 'react';
import type { TextFieldProps } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField } from '@mui/material';

const SalutationSelect: FC<TextFieldProps> = (props) => {
    const { t } = useTranslation();

    const salutations = {
        '': ' ',
        'M': t('formFields.male'),
        'W': t('formFields.female'),
        'U': t('formFields.undefined'),
    };

    return (
        <TextField
            onChange={event => props.form.setFieldValue(props.field.name, event.target.value)}
            {...props}
            select
            SelectProps={
                {
                    MenuProps: {
                        disableScrollLock: true,
                    },
                }
            }
        >
            {Object.entries(salutations)
                .map(([code, salutation]) => (
                    <MenuItem key={code} value={code}>
                        {salutation}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default SalutationSelect;
