import * as React from 'react';
import { useContext } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PayPalScriptOptions } from '@paypal/paypal-js';
import PayPalButtonWrapper from '../components/Payment/PayPalButtonWrapper';
import useConfig from '../hooks/useConfig';
import WorkflowContext from '../contexts/WorkflowContext';
import ConfigContext from '../contexts/ConfigContext';
import { useNavigate } from 'react-router';
import { backendApi } from '../api/backendApi';
import { ExtendedTypography } from '../theme';

const Payment: React.FC = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const options: PayPalScriptOptions = {
        'client-id': config.clientId,
        'currency': config.currency,
    };
    const workflowContext = useContext(WorkflowContext);
    const configContext = useContext(ConfigContext);
    const navigate = useNavigate();
    const { resetWorkflowValues } = useContext(WorkflowContext);

    if (workflowContext.config.formId === null) {
        navigate('/error');
        return <div />;
    }
    const amount = workflowContext.config.amount + configContext.config.versandkosten;

    //TODO: probably, we can remove some asyncs and awaits for api calls
    const sendToServer = async (details: object): Promise<void> => {
        if (workflowContext.config.formId === null) {
            navigate('/error');
        } else {
            await backendApi.postPayPalOrder(
                workflowContext.config.formId,
                details,
            ).then(() => {
                resetWorkflowValues();
                navigate('/done');
            }).catch(() => {
                navigate('/error');
            });
        }
    };

    return (
        <Container className={'page'}>
            <ExtendedTypography variant="h2">{t('payment.payment')}</ExtendedTypography>
            <ExtendedTypography variant="highlight">Betrag: {new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(amount)}</ExtendedTypography>
            <PayPalScriptProvider options={options} deferLoading={false}>
                <PayPalButtonWrapper
                    options={options}
                    onSuccess={sendToServer}
                    amount={amount}
                    currency={config.currency}
                    showSpinner={true}
                />
            </PayPalScriptProvider>
        </Container>
    );
};

export default Payment;
