import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ConfigProvider } from './contexts/ConfigContext';
import { AppRootElement } from './config';

const root = ReactDOM.createRoot(AppRootElement);
root.render(
    <React.StrictMode>
        <ConfigProvider>
            <App />
        </ConfigProvider>
    </React.StrictMode>,
);

reportWebVitals();
